(function() {
    document.addEventListener('DOMContentLoaded', function() {
        var $ = jQuery;

        $(document).ready(function(){
            console.log('ready');
            $('.go_to').click( function(){ 
                var scroll_el = $(this).attr('href'); 
                if ($(scroll_el).length !== 0) { 
                    $('html, body').animate({
                        scrollTop: $(scroll_el).offset().top
                    }, 1500); 
                }
                return false; 
            });
        });
    });
})();